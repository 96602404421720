export const CREATE_BLOCK_PARKING = `
  mutation ($data: FacilityParkingReservationBlockInput!) {
    blockParkingReservation(data: $data) {
        id
        bookingId 
        fromDate
        toDate
        facilityParkingId
        carNumber
        height
        parkingNumber
        note
        color
        block
    }
  }
`
