<template>
  <base-calendar>
  <template v-slot:actions>
    <SearchBooking></SearchBooking>
  </template>
    <template v-slot:default>
      <div class="mt-5 flex-header">
        <v-btn icon  @click="nextOrPrevDayAutoDate('sub')" ><v-icon size="35">mdi-chevron-double-left</v-icon></v-btn>
        <v-btn icon  @click="nextOrPrevDayAutoDate('add')" ><v-icon size="35">mdi-chevron-double-right</v-icon></v-btn>
        <v-btn
          outlined
          small
          class="mr-5 ml-1 btn-custom-gray fz-14 color-black-text"
          @click="chooseToday"
        > {{ $t('common.toDay') }}
        </v-btn>

        <!-- Date picker -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon>mdi-calendar-month</v-icon>
                <v-btn
                  outlined
                  v-on="on"
                  small
                  class="bg--btn-white mr-5 ml-1 btn-custom-gray fz-14 color-black-text"
                  >{{ formatDate(filterFromDate, true) }}</v-btn
                >
              </div>
            </template>
            <v-date-picker
              v-model="filterFromDate"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
        <!--  -->

        <span class="fz-12 color-black-text">{{ $t('roomAssignment.period') }}</span>
         <v-select
          v-model="forTimeFilterSelected"
          :items="forTimeFilter"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          item-text="name"
          item-value="id"
          class="v-select-custom ml-2"
        ></v-select>
        <span class="ml-2 fz-12 color-black-text">{{ $t('common.facility') }}</span>
        <v-select
          v-model="facilitySelected"
          :items="facilitySelectList.length > 0 ? facilitySelectList : []"
          dense
          small
          item-text="name"
          item-value="id"
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-2 facility-list"
        ></v-select>
      </div>
      <div class="my-5 calendar-carParking">
        <div class="calendar-custom" :style="`height: ${(heightParking >= 700 ? '700px': 'auto')}; overflow-y: auto;`">
          <div class="calendar-custom_block first" ref="heightParking">
            <div class="calendar-custom_item pos-sticky"></div>
            <div class="calendar-custom_item pos-sticky"></div>
            <div class="calendar-custom_item pos-sticky fz-12 color-black-text">{{ $t('texts.stock') }}</div>
            <div class="calendar-custom_item pos-sticky fz-12 color-black-text">{{ $t('texts.numberOfWaitingList') }}</div>
            <div
              v-for="(typeCar, idx) in getTypeCarsOld"
              :key="`title${idx}`"
              class="calendar-custom_item pos-sticky fz-12 color-black-text"
              :title="`${typeCar.name}`"
              :class="{overbook: typeCar.overbook}"
            >
            <!-- {{typeCar.id}}- -->
              {{ typeCar.nameConvert }}
            </div>
          </div>
          <div class="cus" style="z-index: 1;">
            <div class="calendar-custom">
                <template v-for="(occurrencesDateFilter, index) in occurrencesDateFilter">
                  <div :style="`width: ${(100 * occurrencesDateFilter)}px !important; padding-left: 8px; border-bottom: none; height: 19px; border-top: 1px solid #dee2e6; border-right: 1px solid #dee2e6;`" :key="`${occurrencesDateFilter}-${index}`" class="fz-11">{{ index }}
                      <template v-if="checkShowTextDateFilter(occurrencesDateFilter)">
                        <span class="sub-occurrencesDateFilter" v-for="num in checkShowTextDateFilter(occurrencesDateFilter)" :key="`countText-${num}`" style="margin-left: 320px">
                          {{ index }}
                        </span>
                      </template>
                    </div>
                </template>
              </div>
            <div class="d-inline-flex">
              <div class="calendar-custom_block ranger" v-for="(parkingStatistic, index) in getParkingStatistics" :key="`stats-${index}`">
                <div class="calendar-custom_item rangeritem" v-text="formatDate(parkingStatistic.date, false)" :class="{'color-red-text' : formatDate(parkingStatistic.date, false).substr(3, 4) == '(日)' || arrHolidays.includes(parkingStatistic.date) }"></div>
                <div class="calendar-custom_item rangeritem" :class="parkingStatistic.lackOfParking ? 'color-red-text' : ''">{{ parkingStatistic.quanlity }}</div>
                <div class="calendar-custom_item rangeritem">{{ parkingStatistic.numberWaiting }}</div>

                <!-- begin ranger color -->
                <div
                  v-on:drop="drop"
                  v-on:dragover="allowDrop"
                  v-for="(typeCar, idx) in getTypeCarsOld"
                  :key="`car-rsv-spot-${idx}`"
                  class="calendar-custom_item no-padding rangeritem"
                  :class="{overbook: typeCar.overbook}"
                  :id="'d-' + formatDateRanger(parkingStatistic.date).substr(0, 10)"
                  @click.stop="checkUser ? clickBlockCreate(parkingStatistic, typeCar) : () => {}"
                >
                  <div class="reservation-table-container" v-if="index===0 && parkingReservations[typeCar.id]">
                    <template v-for="(infoCarPark, idx) in parkingReservations[typeCar.id].blocks">
                      <div
                        :id="'drag' + idx"
                        :key="infoCarPark.id"
                        :style="infoCarPark.style"
                        class="calendar_event custom_ranger_block reservation-item block bg-contract--parking-block"
                        @click.stop="checkUser ? clickBlockEdit(infoCarPark, typeCar) : () => {}"
                      >
                        <span class="calendar_event_item text-center pd-text-block fz-11">
                          {{  showTextRanger(infoCarPark.block, infoCarPark) }}
                        </span>
                      </div>
                    </template>
                    <template v-for="(infoCarPark, idx) in parkingReservations[typeCar.id].normals">
                      <div
                        :id="'drag' + idx"
                        :key="infoCarPark.id"
                        :style="infoCarPark.style"
                        :class="(infoCarPark.booking && bookingTypesList.lenght > 0) ? itemRowBackground(infoCarPark.booking.bookingTypeId, true) : itemRowBackground( 'その他予約', false)"
                        class="calendar_event custom_ranger reservation-item normal"
                        @click.stop="clickRanger(infoCarPark)"
                      >
                        <span class="calendar_event_item text-center fz-11">
                          <!-- {{infoCarPark.facilityParkingId}}-{{infoCarPark.id}}- -->
                          {{ showTextRanger(infoCarPark.block, infoCarPark) }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- end ranger color -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <CalendarParkingBlock
        v-if="isShowConfirmCreateBlock"
        :list-parking="typeCarsRoot"
        :parking-statistic="parkingStatisticCurrent"
        :type-car-block-current="typeCarBlockCurrent"
        @close="isShowConfirmCreateBlock = false"
        @fetchList="getCarpark(true)"
      />
      <CalendarParkingBlockRemove
        v-if="isShowConfirmEditBlock"
        :list-parking="typeCarsRoot"
        :parking-statistic="infoCarParkBlockCurent"
        :type-car-block-current="typeCarBlockCurrent"
        @close="isShowConfirmEditBlock = false"
        @fetchList="getCarpark(true)"
      />
    </template>
  </base-calendar>
</template>

<script>
import gql from 'graphql-tag'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import moment from 'moment'
import _ from 'lodash'
import BaseCalendar from '../../../../components/Calendar/BaseCalendar'
import { listContractBackground } from '@/constants/contractColors'
import { CAR_PARK_LIST } from '@/api/graphql/calendar/carPark'
import { nextOrPrevDay, getDaysArray, nextOrPrevDayAuto } from '@/utils/handleDate'
import CalendarParkingBlock from '@/components/Calendar/CalendarParkingBlock'
import CalendarParkingBlockRemove from '@/components/Calendar/CalendarParkingBlockRemove'
import { mapMutations, mapGetters } from 'vuex'
import { BOOKING_TYPES_LIST } from '@/api/graphql/bookingDetail/booking-detail-basic'
import SearchBooking from '@/components/SearchBooking'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { isoDate } from '@/utils/dateUtil'
import { calculateOverlpas } from '@/utils/calendar'

export default {
  name: 'CalendarCarPark',
  components: {
    BaseCalendar,
    CalendarParkingBlock,
    SearchBooking,
    CalendarParkingBlockRemove
  },
  data () {
    return {
      arrHolidays: [],
      // data filter
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDate: false,
      forTimeFilterSelected: 4,
      forTimeFilter: [
        {
          id: 1,
          name: '1年'
        },
        {
          id: 2,
          name: '3ヶ月'
        },
        {
          id: 3,
          name: '1ヶ月'
        },
        {
          id: 4,
          name: '2週間'
        },
        {
          id: 5,
          name: '1週間'
        }
      ],
      nextOrPrevDay,
      nextOrPrevDayAuto,
      getDaysArray,
      facilitySelectList: [],
      facilitySelected: null,
      filterFromDate: new Date().toISOString().substr(0, 10),

      // list carpark
      getDaysArrayRoot: null,
      loadingPage: false,
      parkingStatistics: [],
      infoCarParks: [],
      typeCars: null,
      typeCarsRoot: [],
      heightParking: 0,
      occurrencesDateFilter: null,
      arrDay: [],
      bookingTypesList: [],

      // create block
      isShowConfirmEditBlock: false,
      infoCarParkBlockCurent: null,
      isShowConfirmCreateBlock: false,
      typeCarBlockCurrent: null,
      parkingStatisticCurrent: null
    }
  },
  computed: {
    dateFormatted () {
      return this.date ? moment(this.date).format('yyyy年MM月DD日') : ''
    },
    ...mapGetters(['getInfoCarParks', 'getTypeCarsRoot', 'getTypeCars', 'getParkingStatistics']),
    parkingByDateAndParkingId () {
      const result = {}
      this.getInfoCarParks.forEach((infoCarPark) => {
        const date = this.formatDateRanger(infoCarPark.fromDate).substr(0, 10)
        if (!result[date]) result[date] = {}
        if (!result[date][infoCarPark.facilityParkingId]) result[date][infoCarPark.facilityParkingId] = []

        result[date][infoCarPark.facilityParkingId].push(infoCarPark)
      })

      return result
    },
    parkingReservations () {
      if (!this.infoCarParks) {
        return {}
      }

      const result = calculateOverlpas(
        this.infoCarParks,
        rsv => rsv.facilityParkingId,
        this.filterFromDate,
        this.filterToDate
      )

      return result
    },
    getTypeCarsOld () {
      return this.getTypeCars.map(typeCar => this.typeCarsArray(typeCar)).flat()
    },
    filterToDate () {
      const baseDate = new Date(this.filterFromDate)
      switch (this.forTimeFilterSelected) {
        case 1:
          return isoDate(baseDate.setFullYear(baseDate.getFullYear() + 1))
        case 2:
          return isoDate(baseDate.setMonth(baseDate.getMonth() + 3))
        case 3:
          return isoDate(baseDate.setMonth(baseDate.getMonth() + 1))
        case 4:
          return isoDate(baseDate.setDate(baseDate.getDate() + 14))
        case 5:
          return isoDate(baseDate.setDate(baseDate.getDate() + 7))
        default:
          return isoDate(baseDate.setMonth(baseDate.getMonth() + 1))
      }
    }
  },

  watch: {
    facilitySelected (newVal) {
      if (newVal && this.loadingPage) {
        this.getCarpark()
      }
    },
    filterFromDate (newVal) {
      if (newVal && this.loadingPage) {
        this.getCarpark()
      }
    },
    forTimeFilterSelected (newVal) {
      if (this.loadingPage) {
        this.getCarpark()
      }
    }
  },
  created () {
    this.getBookingTypeList()
    this.getFacility()
    this.fetchHolidays()
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations({
      setCopyErrorText: 'setCopyErrorText',
      setAlertSuccess: 'setAlertSuccess',
      setAlertError: 'setAlertError',
      setInfoCarParks: 'setInfoCarParks',
      setTypeCarsRoot: 'setTypeCarsRoot',
      setTypeCars: 'setTypeCars',
      setParkingStatistics: 'setParkingStatistics',
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide',
      setPermissionUser: 'setPermissionUser',
      setRoleAdminUser: 'setRoleAdminUser'
    }),
    allowDrop (event) {
      event.preventDefault()
    },
    drag (event) {
      event.dataTransfer.setData('text', event.target.id)
    },
    async fetchHolidays () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`
            query(
              $from: DateString!
              $to: DateString!
            ) {
              holidays(from: $from, to: $to) {
                holidayDate
                name
              }
            }
          `,
          variables: {
            from: this.filterFromDate,
            to: this.filterToDate
          }
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          data.data.holidays.forEach(e => { this.arrHolidays.push(e.holidayDate) })
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          const errorTmp = await this.handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser
          )
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },
    drop (event) {
      event.preventDefault()
      var data = event.dataTransfer.getData('text')
      event.target.appendChild(document.getElementById(data))
    },
    numbDays (c, d, checkRanger = false) {
      const start = moment(c, 'YYYY-MM-DD')
      const end = moment(d, 'YYYY-MM-DD')
      // Difference in number of days
      const diffDay = moment.duration(end.diff(start)).asDays()
      if (diffDay > 0) {
        if (checkRanger) {
          return diffDay + 1
        }
        return diffDay
      }
      return 1
    },
    numbDaysInfoCar (c, d) {
      const start = moment(c, 'YYYY-MM-DD')
      const end = moment(d, 'YYYY-MM-DD')
      // Difference in number of days
      return moment.duration(end.diff(start)).asDays()
    },
    itemRowBackground (item, statusBookingType = false) {
      if (statusBookingType) {
        const nameColorTmp = this.bookingTypesList.filter(itemBook => itemBook.id === item)
        const codeColor = nameColorTmp.length > 0 ? nameColorTmp[0].code : ''
        const nameColor = nameColorTmp.length > 0 ? nameColorTmp[0].name : ''
        let colorBg = 'その他予約'
        if (nameColor || codeColor) {
          const keyListContractBackground = Object.keys(listContractBackground)
          keyListContractBackground.map((itemBg) => {
            if (itemBg.includes(nameColor) || itemBg.includes(codeColor)) {
              colorBg = itemBg
            }
          })
          return listContractBackground[colorBg]
        }
        return listContractBackground[colorBg]
      }
      return listContractBackground[item]
    },
    formatDate (value, fomat = false) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      if (fomat) {
        return moment(value).format('yyyy年MM月DD日 (' + d + ')')
      }
      return moment(value).format('D (' + d + ')')
    },
    formatDateRanger (value) {
      return moment(value).format('yyyy-MM-DD')
    },

    checkShowTextDateFilter (occurrencesDateFilter) {
      let countText = 0
      if (occurrencesDateFilter > 5) {
        countText = Math.floor(occurrencesDateFilter / 5)
      }
      if (occurrencesDateFilter === 30 || occurrencesDateFilter === 31) {
        countText = countText + 1
      }
      return countText
    },
    async getBookingTypeList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING_TYPES_LIST}`
        })
        .then((data) => {
          this.bookingTypesList = data.data.bookingTypesList
          this.setLoadingOverlayHide()
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_TYPES_LIST, null, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },

    // begin filter
    async getFacility () {
      this.setLoadingOverlayShow()
      const query = `query {
          facilityList(filter: {
            types: [TVS, CNV]
          }) {
            id
            name
          }
        }`
      await this.$apollo.query({
        query: gql`${query}`
      }).then((data) => {
        const facilityList = data.data.facilityList
        if (facilityList.length > 0) {
          this.facilitySelectList = this.facilitySelectList.concat(facilityList)
          this.facilitySelected = this.facilitySelectList[0].id
          this.getCarpark()
        }
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(query, null, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    async chooseToday () {
      this.heightParking = 0
      this.filterFromDate = this.$today()
    },
    // end filter

    // begin list parking room
    formatRangerFilter (value) {
      if (value) {
        return moment(value, 'yyyy年MM月').format('yyyy年MM月')
      }
      return ''
    },
    showTextRanger (block = false, infoCarPark = null) {
      if (infoCarPark) {
        if (block) {
          if (infoCarPark.note && infoCarPark.note.length > 30) {
            return `${infoCarPark.note.substr(0, 15)}...${infoCarPark.note.substr(-10)}`
          } else {
            return infoCarPark.note
          }
        }
        if (infoCarPark.booking && infoCarPark.booking.representative?.name) {
          if (infoCarPark.booking.representative.name.length > 30) {
            return `${infoCarPark.booking.representative.name.substr(0, 15)}...${infoCarPark.booking.representative.name.substr(-10)}`
          } else {
            return infoCarPark.booking.representative.name
          }
        }
      }
      return ''
    },
    nextOrPrevDayAutoDate (direct) {
      this.filterFromDate = this.nextOrPrevDayAuto(this.filterFromDate, direct, this.forTimeFilterSelected)
    },
    async getCarpark (reload = false) {
      this.setLoadingOverlayShow()
      // begin process template date header
      const getDaysArray = this.getDaysArray(new Date(this.filterFromDate), new Date(this.filterToDate))
      this.getDaysArrayRoot = getDaysArray
      const getDaysArrayFomat = getDaysArray.map((ite) => {
        ite = this.formatRangerFilter(ite)
        return ite
      })
      this.occurrencesDateFilter = _.countBy(getDaysArrayFomat)
      // end process template date header
      this.parkingStatistics = []
      if (!reload) {
        this.heightParking = 0
        this.typeCars = []
        this.typeCarsRoot = []
        this.setParkingStatistics({ parkingStatistics: [] })
        this.setTypeCars({ typeCars: [] })
        this.setTypeCarsRoot({ typeCarsRoot: [] })
        this.setInfoCarParks({ infoCarParks: [] })
      }
      const variables = {
        facilityId: parseInt(this.facilitySelected),
        filter: {
          fromDate: this.filterFromDate,
          toDate: this.filterToDate,
          facilityId: parseInt(this.facilitySelected)
        },
        filterCancel: {
          facilityId: parseInt(this.facilitySelected),
          fromDate: this.filterFromDate,
          toDate: this.filterToDate,
          inEffect: true
        }
      }
      await this.$apollo.query({
        query: gql`${CAR_PARK_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.loadingPage = true
        this.totalQuanlityAllFacilityParkingList = 0
        const facilityParkingList = data.data.facilityParkingList
        const parkingReservationList = data.data.parkingReservationList
        const facilityParkingCancelWaitlistCountList = data.data.facilityParkingCancelWaitlistCountList
        if (facilityParkingList.length > 0) {
          this.totalQuanlityAllFacilityParkingList = facilityParkingList.reduce((n, { quantity }) => n + quantity, 0)
          this.typeCarsRoot = facilityParkingList
          this.setTypeCarsRoot({ typeCarsRoot: facilityParkingList })
        }
        // 在庫
        const arrIdFacilityParkingList = facilityParkingList.map(item => item.id)
        // console.log(arrIdFacilityParkingList, 'arrIdFacilityParkingList')
        getDaysArray.map((itemDay) => {
          const itemTmp = {
            date: itemDay,
            quanlity: '',
            numberWaiting: 0,
            countDayAll: 0,
            lackOfParking: false
          }
          let countDay = 0
          parkingReservationList.items.map((itemRes) => {
            if (arrIdFacilityParkingList.includes(itemRes.facilityParkingId)) {
              if (this.validateDateBetweenTwoDatesandCheckBlock(itemRes.fromDate, itemRes.toDate, itemDay, itemRes)) {
                countDay += 1
              }
              return itemRes
            }
          })
          if (parseInt(countDay) > parseInt(this.totalQuanlityAllFacilityParkingList)) {
            itemTmp.lackOfParking = true
          }
          itemTmp.quanlity = parseInt(countDay) > parseInt(this.totalQuanlityAllFacilityParkingList) ? `0(${parseInt(this.totalQuanlityAllFacilityParkingList) - parseInt(countDay)})` : this.totalQuanlityAllFacilityParkingList - countDay
          itemTmp.countDayAll = countDay
          this.parkingStatistics.push(itemTmp)
          return itemDay
        })

        // キャンセル待ち件数
        if (facilityParkingCancelWaitlistCountList.length > 0) {
          this.parkingStatistics.map((item) => {
            facilityParkingCancelWaitlistCountList.map((itemCancel) => {
              if (itemCancel.stayDate === item.date) {
                item.numberWaiting = itemCancel.count
              }
              return itemCancel
            })
            return item
          })
        }
        // console.log(this.parkingStatistics, 'parkingStatistics')
        this.setParkingStatistics({ parkingStatistics: this.parkingStatistics })

        // detail ranger facilityParkingList
        this.typeCars = facilityParkingList.map((item) => {
          item.nameConvert = `${item.name.slice(0, 19)} 車高制限:${typeof item.maxHeight === 'number' ? `${item.maxHeight}cm` : 'なし'}`
          item.idSort = item.id
          return item
        })
        this.typeCars = _.orderBy([...this.typeCars], ['idSort'], ['asc'])
        this.infoCarParks = parkingReservationList.items
        // console.log(this.typeCars, 'this.typeCars')
        this.setTypeCars({ typeCars: this.typeCars })

        // update fromDate if date range change (fromDate not in array getDaysArray)
        if (this.infoCarParks.length > 0) {
          getDaysArray.map((date) => {
            this.infoCarParks.map((item) => {
              const numberDay = this.numbDaysInfoCar(item.fromDate, item.toDate)
              const validateDateBetweenTwoDates = this.validateDateBetweenTwoDates(item.fromDate, item.toDate, date)
              if (numberDay >= 1 && validateDateBetweenTwoDates && !getDaysArray.includes(item.fromDate)) {
                item.fromDateRoot = item.fromDate
                item.fromDate = date
                item.position = true
                item.numberDay = this.numbDaysInfoCar(date, item.toDate)
              }
              return item
            })
            return date
          })
        }
        // console.log(this.infoCarParks, 'this.infoCarParks')
        this.setInfoCarParks({ infoCarParks: this.infoCarParks })

        // height list parking
        this.$nextTick(() => {
          this.heightParking = this.$refs.heightParking ? this.$refs.heightParking.clientHeight : 0
        })
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        this.loadingPage = true
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(CAR_PARK_LIST, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    getReservationForDateAndParking (date, parkingId) {
      return this.parkingByDateAndParkingId[date]?.[parkingId]
    },
    getvalidDate (d) {
      return new Date(d)
    },
    validateDateBetweenTwoDatesandCheckBlock (fromDate, toDate, givenDate, item) {
      return (this.getvalidDate(givenDate) < this.getvalidDate(toDate) && this.getvalidDate(givenDate) >= this.getvalidDate(fromDate))
    },
    validateDateBetweenTwoDates (fromDate, toDate, givenDate) {
      return this.getvalidDate(givenDate) <= this.getvalidDate(toDate) && this.getvalidDate(givenDate) >= this.getvalidDate(fromDate)
    },
    clickRanger (bookingCurent) {
      const routeDate = this.$router.resolve({ path: '/reservation/contract-detail', query: { id: bookingCurent.bookingId, tab: 'parking-place' } })
      window.open(routeDate.href, '_blank')
    },
    // end lisr parking room

    // create Block
    clickBlockCreate (parkingStatistic, typeCarsBlockCurrent) {
      this.typeCarBlockCurrent = typeCarsBlockCurrent.idSort
      this.parkingStatisticCurrent = parkingStatistic
      this.isShowConfirmCreateBlock = true
    },
    // remove block
    clickBlockEdit (infoCarPark, typeCarsBlockCurrent) {
      this.typeCarBlockCurrent = typeCarsBlockCurrent
      this.infoCarParkBlockCurent = infoCarPark
      this.isShowConfirmEditBlock = true
    },
    typeCarsArray (typeCar) {
      const reservationData = this.parkingReservations[typeCar.id]
      const count = typeCar.quantity
      const overBookingCount = reservationData ? Math.max(reservationData.maxOverlap + 1 - typeCar.quantity, 0) : 0
      typeCar.quantityWithOverbook = count + overBookingCount

      if (count === 0) {
        if (overBookingCount === 0) {
          // if there is no parking, and no overbooking, return empty array to hide it from the UI
          return []
        } else {
          return [
            {
              ...typeCar,
              overbook: true
            },
            ...[...Array(overBookingCount - 1)].map(() => ({ overbook: true }))
          ]
        }
      }

      const result = [
        typeCar,
        ...[...Array(count - 1)].map(() => ({})),
        ...[...Array(overBookingCount)].map(() => ({ overbook: true }))
      ]
      return result
    }
  },
  beforeDestroy () {
    this.facilitySelected = null
    this.facilitySelected = []
    this.filterFromDate = new Date().toISOString().substr(0, 10)
    this.forTimeFilterSelected = 3
  }
}
</script>

<style scoped lang="scss">
  .calendar-custom_block.first {
    border-left: none;
    position: sticky;
    left: 0;
    z-index: 2;
    flex: none;
    background-color: #fff;
  }
  .pos-sticky {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
  }
  .calendar-carParking .calendar-custom_block.first {
    width: auto;
    border-right: none;
  }
  .calendar-custom_block.ranger {
    width: 100px;
    position: relative;
    .rangeritem {
      min-width: unset !important;
      font-size: 10px;
    }
  }
  .calendar-custom_item {
    &.overbook {
      background-color: red;
    }
  }
  .calendar-custom_block.ranger:first-child  {
    text-align: center;
    border-left: 0;
    .rangeritem {
      font-weight: normal;
    }
  }
  .rangeritem {
    cursor: pointer;
    color: #000000;
  }
 .rangeritem .calendar_event.custom_ranger {
   margin-bottom: 5px;
  }
 .rangeritem .calendar_event.custom_ranger_block {
   margin-bottom: 4px;
  }
  .pd-text-block {
    padding-top: 2px;
    color: #707070
  }
  .color-red-text {
    color: #FF0000;
  }
  .color-black-text {
    color: #000000 !important;
  }
  .fz-10 {
  font-size: 10px;
  }
  .fz-9 {
    font-size: 9px;
  }
  .fz-8 {
    font-size: 8px;
  }
  .fz-11 {
    font-size: 11px;
  }
  .fz-12 {
    font-size: 12px;
  }
  .fz-14 {
    font-size: 14px;
  }
  ::v-deep .v-select__selection{
    font-size: 12px !important;
    color: #000000 !important;
  }
  .reservation-table-container {
    position: relative;
    & .reservation-item {
      position: absolute;
      &.normal {
        margin-top: 1px;
        height: 17px;
      }
      &.block {
        height: 19px;
        border-radius: 0px;
        border: 1px solid white;
      }
    }
  }
</style>
