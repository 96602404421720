export const CAR_PARK_LIST = `query (
    $facilityId: Int!
    $filter: ListFacilityParkingReservationFilterInput
    $filterCancel: FacilityParkingCancelWaitListCountFilterInput!
) {
    facilityParkingList(facilityId: $facilityId) {
    id
    allowCancelWait
    name #駐車場の名称
    quantity # 数量（この数だけ行を出す）
    maxHeight #最大車高
  }
  
  parkingReservationList(
    filter: $filter
     
    orderBy: [{
      fieldName: "reserve.fromDate"
      direction: "ASC"
    }]
  ) {
    items {
      id
      facilityParkingId
      facilityParking{
        id
        quantity
        facilityId
        name
        maxHeight
      }
      bookingId
      fromDate #DateString!
      toDate # DateString!
      block #trueならブロック予約なので、以下のBookingはNullになる
      note #blockの場合はこちらを表示
      booking {
        bookingGroupId
        bookingTypeId #色の判断に使用
        representative {
          name #この名前を表示する
        }
      }
    }
  }
  facilityParkingCancelWaitlistCountList( #キャンセル数
    filter: $filterCancel
  ) {
    stayDate #DateString! 宿泊日
    count # Int! 数値
  }
}`
