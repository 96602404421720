<template>
  <v-dialog v-model="isShowConfirmCreateBlock" width="320px" @click:outside="closeDialog">
    <v-form ref="formBlock">
      <v-card>
        <v-card-title class="title-dialog justify-space-between">
          <span class="title-block">駐車場ブロック</span>
           <v-select
            v-model="parkingSelected"
            :items="listParking"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            item-text="name"
            item-value="id"
            class="v-select-custom ml-2"
          ></v-select>
        </v-card-title>
        <v-card-text>
          <p class="font-weight-black mb-0 fz-12 color-text-61">適用範囲</p>
          <span class="fz-11">{{ formatDate(parkingStatistic.date) + 'から' }}</span>
          <!-- Date picker -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon>mdi-calendar-month</v-icon>
                <v-btn
                  outlined
                  v-on="on"
                  small
                  class="bg--btn-white mr-5 ml-1 btn-custom-gray fz-13"
                  >{{ formatDate(toDateBlock) }}</v-btn
                >
              </div>
            </template>
            <v-date-picker
              v-model="toDateBlock"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate = false"
              class="v-date-picker-custom"
              :min="minToDate"
            ></v-date-picker>
          </v-menu>
          <p class="font-weight-black mb-0 mt-5 fz-12 color-text-61">コメント</p>
          <v-text-field
            v-model="noteBlock"
            outlined
            dense
            type="text"
            class="form-control"
            :rules="[noteBlockBlank, noteBlockLenght]"
          ></v-text-field>
        <!--  -->
        </v-card-text>
        <v-card-text class="mt-n5">
          <v-row>
            <v-col :cols="6" class="d-flex justify-start">
              <v-btn
                width="80"
                color="var(--bt__blue)"
                class="mr-3 d-flex align-center btn_status white--text"
                @click="submitFormBlock"
              >
                適用
              </v-btn>
              <v-btn
                width="80"
                color="var(--bt__red)"
                class="mr-3 d-flex align-center btn_status white--text"
                disabled
              >
                解除
              </v-btn>
              <v-btn
                width="104"
                class="black--text btn_status btn--gray-ounlined"
                outlined
                color="var(--bt__white)"
                @click="closeDialog"
              >
                <span class="text--gray-darken">キャンセル</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { formatDate, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { mapMutations } from 'vuex'
import { CREATE_BLOCK_PARKING } from '@/api/graphql/calendar/createBloclParking'
import gql from 'graphql-tag'

export default {
  name: 'CalendarParkingBlock',
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: true
    },
    listParking: {
      type: Array,
      required: true,
      default: () => []
    },
    parkingStatistic: {
      type: Object,
      required: true,
      default: () => {}
    },
    typeCarBlockCurrent: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    isShowConfirmCreateBlock () {
      return this.isShow
    },
    minToDate () {
      const date = new Date(this.parkingStatistic.date)
      date.setDate(date.getDate() + 1)
      return date.toISOString().substr(0, 10)
    }
  },
  data () {
    return {
      noteBlock: '',
      parkingSelected: null,
      menuDate: false,
      toDateBlock: new Date().toISOString().substr(0, 10),
      noteBlockBlank (v) {
        return !!v || 'コメントを入力してください'
      },
      noteBlockLenght (v) {
        return (!v || v.length <= 255) || '255文字以上で入力してください'
      }
    }
  },
  mounted () {
    this.parkingSelected = this.typeCarBlockCurrent
    this.toDateBlock = new Date(new Date(this.parkingStatistic.date).setDate(new Date(this.parkingStatistic.date).getDate() + 3)).toISOString().substr(0, 10)
  },

  methods: {
    formatDate,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText', 'setAlertSuccess', 'setErrorDialog', 'setAlertError', 'setLoadingOverlayShow', 'setLoadingOverlayHide', 'setPermissionUser', 'setRoleAdminUser']),

    closeDialog () {
      this.$refs.formBlock.resetValidation()
      this.noteBlock = ''
      this.$emit('update:isShow', false)
      this.$emit('close')
    },

    // create block
    async submitFormBlock () {
      if (this.$refs.formBlock.validate()) {
        this.setLoadingOverlayShow()
        const variables = {
          data: {
            facilityParkingId: this.parkingSelected,
            fromDate: this.parkingStatistic.date,
            toDate: this.toDateBlock,
            note: this.noteBlock
          }
        }
        await this.$apollo.mutate({
          mutation: gql`${CREATE_BLOCK_PARKING}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          if (data.data.blockParkingReservation && data.data.blockParkingReservation.id) {
            this.setAlertSuccess(this.$t('messages.successfulNewCreation'))
            this.closeDialog()
            this.$emit('fetchList')
          }
          this.setLoadingOverlayHide()
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(CREATE_BLOCK_PARKING, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title-block {
  font-size: 14px;
  color: #AAAAAA;
}
::v-deep .v-select__selection{
  font-size: 14px !important;
  color: #AAAAAA !important;
}
::v-deep .v-messages__message {
  font-size: 12px !important;
}
.color-text-61 {
  color: #616161;
}
.v-select-custom {
  max-width: 150px;
}
.btn_status {
  font-size: 16px;
}
.fz-10 {
  font-size: 10px;
}
.fz-9 {
  font-size: 9px;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.btn--gray-ounlined {
  border: solid 1px #BDBDBD !important;
}
</style>
